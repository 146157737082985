<template>
	<div>
		<div  class="bottom_box">
			<div style="width: 100%;height: 240px;">
				<div class="bottom_small_box">
					<div class="bottom_small_left" style="width: 32%;">
						<div class="bottom_small_left_t">服务项目</div>
						<div class="bottom_small_left_l"></div>
						<div class="bottom_small_left_b">
							<div v-for="(item,index) in list" :key='index'>{{item}}</div>
						</div>
					</div>
					<div class="bottom_small_left" style="width: 40%;">
						<div class="bottom_small_left_t">联系我们</div>
						<div style="display: flex;">
							<div class="bottom_small_center_b">
								<img src="../assets/bgi1.png" alt="" />
								<div>
									<div>400-6606-033</div>
									<div>周一至周日 8:00~18:00</div>
								</div>
							</div>
							<div class="bottom_small_center_b" style="margin-left: 40px;">
								<img src="../assets/bgi1.png" alt="" />
								<div>
									<div>400-6606-033</div>
									<div>周一至周日 8:00~18:00</div>
								</div>
							</div>
						</div>
					</div>
					<div class="bottom_small_left" style="width: 20%;">
						<div class="bottom_small_left_t">应用端</div>
						<div class="bottom_small_right_b">
							<div>
								<img src="../assets/logo.png" alt="" />
								<div>微信小程序</div>
							</div>
							<div>
								<img src="../assets/logo.png" alt="" />
								<div>APP</div>
							</div>
						</div>
					</div>
				</div>
			
			</div>
			<div class="b_b">
				©Copyright 2009-2014 版权所有 XXXXXXXXXXXX 蜀ICP备16004478号 All rights reserved 技术支持：XXXXXXXXX
			</div>
		</div>
		
	</div>

</template>
<script>
	export default {

		data() {
			return {
				list: ["上门服务", "上门服务", "上门服务", "上门服务", "上门服务", "上门服务"]
			}
		},


		mounted() {},
		methods: {}
	}
</script>


<style scoped lang="less">
	.bottom_box {
		width: 100%;
		
		background-color: #282E31;
		padding-top: 80px;
	}

	.bottom_small_box {
		width: 70%;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.bottom_small_left {

		height: 70px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		border-right: 1px solid #3C4144;
	}

	.bottom_small_left_t {
		font-family: PingFangSC, PingFang SC;
		font-weight: 600;
		font-size: 18px;
		color: #FFFFFF;
		font-style: normal;
		text-transform: none;
	}

	.bottom_small_left_l {
		width: 26px;
		height: 2px;
		background: #A9ABAD;
		margin-top: 2px;
	}

	.bottom_small_left_b {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-top: 25px;

		div {
			width: 25%;
			height: 18px;
			// padding-right: 10px;
			color: #A9ABAD;
			border-right: 1px solid #3C4144;
			margin-top: 8px;
		}
	}

	.bottom_small_center_b {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 25px;

		img {
			width: 38px;
			height: 38px;
		}

		div {
			margin-left: 5px;
		}

	}

	.bottom_small_center_b>div:nth-of-type(1) {
		font-family: AppleSystemUIFont;
		font-size: 15px;
		color: #A9ABAD;
		font-style: normal;
		text-transform: none;
	}

	.bottom_small_center_b>div:nth-of-type(2) {
		font-family: AppleSystemUIFont;
		font-size: 13px;
		color: #333333;
		font-style: normal;
		text-transform: none;
		margin-top: 2px;
	}

	.bottom_small_right_b {
		display: flex;
		align-items: center;
		margin-top: 25px;

	}

	.bottom_small_right_b>div {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-right: 30px;
	}

	.bottom_small_right_b>div>img {
		width: 66px;
		height: 66px;
	}

	.bottom_small_right_b>div>div {
		width: 66px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 12px;
		color: #A9ABAD;
		font-style: normal;
		margin-top: 5px;
		text-align: center;

	}
	.b_b{
		height: 50px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 13px;
		color:  #A9ABAD;
		font-style: normal;
		text-transform: none;
		display: flex;
		align-items: center;
		justify-content: center;
		border-top: 1px solid #3C4144;
	}
</style>